export const ApoCharacterData = [
  {
    char: 'acheron',
    name: 'Acheron',
    rarity: 'Limited 5*',
    current_app_rate: 56.18,
    prev_app_rate: 24.01,
    round_eidolons: 3513,
    current_avg_round: 3411,
    prev_avg_round: 3320
  },
  {
    char: 'argenti',
    name: 'Argenti',
    rarity: 'Limited 5*',
    current_app_rate: 0.63,
    prev_app_rate: 0.02,
    round_eidolons: 3307,
    current_avg_round: 3263,
    prev_avg_round: 0
  },
  {
    char: 'arlan',
    name: 'Arlan',
    rarity: '4*',
    current_app_rate: 0,
    prev_app_rate: 0,
    round_eidolons: 0,
    current_avg_round: 0,
    prev_avg_round: 0
  },
  {
    char: 'asta',
    name: 'Asta',
    rarity: '4*',
    current_app_rate: 0.39,
    prev_app_rate: 0.73,
    round_eidolons: 3616,
    current_avg_round: 3326,
    prev_avg_round: 3237
  },
  {
    char: 'aventurine',
    name: 'Aventurine',
    rarity: 'Limited 5*',
    current_app_rate: 60.14,
    prev_app_rate: 44.57,
    round_eidolons: 3541,
    current_avg_round: 3419,
    prev_avg_round: 3563
  },
  {
    char: 'bailu',
    name: 'Bailu',
    rarity: 'Standard 5*',
    current_app_rate: 0.61,
    prev_app_rate: 0.74,
    round_eidolons: 3404,
    current_avg_round: 3308,
    prev_avg_round: 3375
  },
  {
    char: 'black-swan',
    name: 'Black Swan',
    rarity: 'Limited 5*',
    current_app_rate: 13.79,
    prev_app_rate: 2.93,
    round_eidolons: 3373,
    current_avg_round: 3290,
    prev_avg_round: 3214
  },
  {
    char: 'blade',
    name: 'Blade',
    rarity: 'Limited 5*',
    current_app_rate: 0.74,
    prev_app_rate: 0.62,
    round_eidolons: 3333,
    current_avg_round: 3191,
    prev_avg_round: 3188
  },
  {
    char: 'boothill',
    name: 'Boothill',
    rarity: 'Limited 5*',
    current_app_rate: 3.24,
    prev_app_rate: 2.95,
    round_eidolons: 3585,
    current_avg_round: 3459,
    prev_avg_round: 3368
  },
  {
    char: 'bronya',
    name: 'Bronya',
    rarity: 'Standard 5*',
    current_app_rate: 6.48,
    prev_app_rate: 6.64,
    round_eidolons: 3566,
    current_avg_round: 3369,
    prev_avg_round: 3415
  },
  {
    char: 'clara',
    name: 'Clara',
    rarity: 'Standard 5*',
    current_app_rate: 0.41,
    prev_app_rate: 1.36,
    round_eidolons: 3264,
    current_avg_round: 0,
    prev_avg_round: 3267
  },
  {
    char: 'dan-heng',
    name: 'Dan Heng',
    rarity: '4*',
    current_app_rate: 0,
    prev_app_rate: 0.03,
    round_eidolons: 0,
    current_avg_round: 0,
    prev_avg_round: 0
  },
  {
    char: 'dr-ratio',
    name: 'Dr. Ratio',
    rarity: 'Limited 5*',
    current_app_rate: 0.51,
    prev_app_rate: 9.94,
    round_eidolons: 3345,
    current_avg_round: 0,
    prev_avg_round: 3356
  },
  {
    char: 'feixiao',
    name: 'Feixiao',
    rarity: 'Limited 5*',
    current_app_rate: 24.34,
    prev_app_rate: 61.33,
    round_eidolons: 3609,
    current_avg_round: 3461,
    prev_avg_round: 3553
  },
  {
    char: 'firefly',
    name: 'Firefly',
    rarity: 'Limited 5*',
    current_app_rate: 61.69,
    prev_app_rate: 62.88,
    round_eidolons: 3641,
    current_avg_round: 3534,
    prev_avg_round: 3424
  },
  {
    char: 'fu-xuan',
    name: 'Fu Xuan',
    rarity: 'Limited 5*',
    current_app_rate: 22.13,
    prev_app_rate: 25.06,
    round_eidolons: 3436,
    current_avg_round: 3345,
    prev_avg_round: 3404
  },
  {
    char: 'gallagher',
    name: 'Gallagher',
    rarity: '4*',
    current_app_rate: 56.52,
    prev_app_rate: 59.65,
    round_eidolons: 3589,
    current_avg_round: 3494,
    prev_avg_round: 3392
  },
  {
    char: 'gepard',
    name: 'Gepard',
    rarity: 'Standard 5*',
    current_app_rate: 0.79,
    prev_app_rate: 0.86,
    round_eidolons: 3467,
    current_avg_round: 3378,
    prev_avg_round: 3308
  },
  {
    char: 'guinaifen',
    name: 'Guinaifen',
    rarity: '4*',
    current_app_rate: 2.12,
    prev_app_rate: 2.4,
    round_eidolons: 3411,
    current_avg_round: 3373,
    prev_avg_round: 3291
  },
  {
    char: 'hanya',
    name: 'Hanya',
    rarity: '4*',
    current_app_rate: 0.02,
    prev_app_rate: 0.14,
    round_eidolons: 0,
    current_avg_round: 0,
    prev_avg_round: 0
  },
  {
    char: 'herta',
    name: 'Herta',
    rarity: '4*',
    current_app_rate: 1.23,
    prev_app_rate: 0.02,
    round_eidolons: 3379,
    current_avg_round: 3164,
    prev_avg_round: 0
  },
  {
    char: 'himeko',
    name: 'Himeko',
    rarity: 'Standard 5*',
    current_app_rate: 1.62,
    prev_app_rate: 11,
    round_eidolons: 3440,
    current_avg_round: 3244,
    prev_avg_round: 3234
  },
  {
    char: 'hook',
    name: 'Hook',
    rarity: '4*',
    current_app_rate: 0.02,
    prev_app_rate: 0.05,
    round_eidolons: 0,
    current_avg_round: 0,
    prev_avg_round: 0
  },
  {
    char: 'huohuo',
    name: 'Huohuo',
    rarity: 'Limited 5*',
    current_app_rate: 14.08,
    prev_app_rate: 15.43,
    round_eidolons: 3425,
    current_avg_round: 3327,
    prev_avg_round: 3402
  },
  {
    char: 'imbibitor-lunae',
    name: 'Imbibitor Lunae',
    rarity: 'Limited 5*',
    current_app_rate: 2.55,
    prev_app_rate: 4.56,
    round_eidolons: 3416,
    current_avg_round: 3222,
    prev_avg_round: 3128
  },
  {
    char: 'jade',
    name: 'Jade',
    rarity: 'Limited 5*',
    current_app_rate: 5.36,
    prev_app_rate: 4.19,
    round_eidolons: 3557,
    current_avg_round: 3324,
    prev_avg_round: 3313
  },
  {
    char: 'jiaoqiu',
    name: 'Jiaoqiu',
    rarity: 'Limited 5*',
    current_app_rate: 27.92,
    prev_app_rate: 16.79,
    round_eidolons: 3604,
    current_avg_round: 3491,
    prev_avg_round: 3363
  },
  {
    char: 'jing-yuan',
    name: 'Jing Yuan',
    rarity: 'Limited 5*',
    current_app_rate: 20.96,
    prev_app_rate: 0.96,
    round_eidolons: 3495,
    current_avg_round: 3371,
    prev_avg_round: 3199
  },
  {
    char: 'jingliu',
    name: 'Jingliu',
    rarity: 'Limited 5*',
    current_app_rate: 0.81,
    prev_app_rate: 0.02,
    round_eidolons: 3301,
    current_avg_round: 3127,
    prev_avg_round: 0
  },
  {
    char: 'kafka',
    name: 'Kafka',
    rarity: 'Limited 5*',
    current_app_rate: 14.64,
    prev_app_rate: 5.46,
    round_eidolons: 3371,
    current_avg_round: 3298,
    prev_avg_round: 3236
  },
  {
    char: 'lingsha',
    name: 'Lingsha',
    rarity: 'Limited 5*',
    current_app_rate: 35.17,
    prev_app_rate: 36.16,
    round_eidolons: 3655,
    current_avg_round: 3548,
    prev_avg_round: 3546
  },
  {
    char: 'luka',
    name: 'Luka',
    rarity: '4*',
    current_app_rate: 0.01,
    prev_app_rate: 0.05,
    round_eidolons: 0,
    current_avg_round: 0,
    prev_avg_round: 0
  },
  {
    char: 'luocha',
    name: 'Luocha',
    rarity: 'Limited 5*',
    current_app_rate: 7.95,
    prev_app_rate: 8.83,
    round_eidolons: 3428,
    current_avg_round: 3338,
    prev_avg_round: 3410
  },
  {
    char: 'lynx',
    name: 'Lynx',
    rarity: '4*',
    current_app_rate: 0.41,
    prev_app_rate: 0.56,
    round_eidolons: 3436,
    current_avg_round: 3314,
    prev_avg_round: 3371
  },
  {
    char: 'march-7th',
    name: 'March 7th',
    rarity: '4*',
    current_app_rate: 0.06,
    prev_app_rate: 0.01,
    round_eidolons: 0,
    current_avg_round: 0,
    prev_avg_round: 0
  },
  {
    char: 'march-7th-swordmaster',
    name: 'March 7th - Hunt',
    rarity: '4*',
    current_app_rate: 9.29,
    prev_app_rate: 31.41,
    round_eidolons: 3570,
    current_avg_round: 3296,
    prev_avg_round: 3507
  },
  {
    char: 'misha',
    name: 'Misha',
    rarity: '4*',
    current_app_rate: 0.02,
    prev_app_rate: 0,
    round_eidolons: 0,
    current_avg_round: 0,
    prev_avg_round: 0
  },
  {
    char: 'moze',
    name: 'Moze',
    rarity: '4*',
    current_app_rate: 1.88,
    prev_app_rate: 2.77,
    round_eidolons: 3521,
    current_avg_round: 3428,
    prev_avg_round: 3436
  },
  {
    char: 'natasha',
    name: 'Natasha',
    rarity: '4*',
    current_app_rate: 0.04,
    prev_app_rate: 0.05,
    round_eidolons: 0,
    current_avg_round: 0,
    prev_avg_round: 0
  },
  {
    char: 'pela',
    name: 'Pela',
    rarity: '4*',
    current_app_rate: 30.53,
    prev_app_rate: 6.26,
    round_eidolons: 3492,
    current_avg_round: 3430,
    prev_avg_round: 3334
  },
  {
    char: 'qingque',
    name: 'Qingque',
    rarity: '4*',
    current_app_rate: 1.45,
    prev_app_rate: 0.37,
    round_eidolons: 3428,
    current_avg_round: 3271,
    prev_avg_round: 0
  },
  {
    char: 'rappa',
    name: 'Rappa',
    rarity: 'Limited 5*',
    current_app_rate: 14.41,
    prev_app_rate: 15.7,
    round_eidolons: 3539,
    current_avg_round: 3453,
    prev_avg_round: 3523
  },
  {
    char: 'robin',
    name: 'Robin',
    rarity: 'Limited 5*',
    current_app_rate: 45.08,
    prev_app_rate: 74.88,
    round_eidolons: 3540,
    current_avg_round: 3407,
    prev_avg_round: 3520
  },
  {
    char: 'ruan-mei',
    name: 'Ruan Mei',
    rarity: 'Limited 5*',
    current_app_rate: 84.81,
    prev_app_rate: 89.76,
    round_eidolons: 3609,
    current_avg_round: 3514,
    prev_avg_round: 3427
  },
  {
    char: 'sampo',
    name: 'Sampo',
    rarity: '4*',
    current_app_rate: 0.19,
    prev_app_rate: 0.03,
    round_eidolons: 0,
    current_avg_round: 0,
    prev_avg_round: 0
  },
  {
    char: 'seele',
    name: 'Seele',
    rarity: 'Limited 5*',
    current_app_rate: 2.14,
    prev_app_rate: 0.29,
    round_eidolons: 3367,
    current_avg_round: 3230,
    prev_avg_round: 0
  },
  {
    char: 'serval',
    name: 'Serval',
    rarity: '4*',
    current_app_rate: 0.73,
    prev_app_rate: 0.23,
    round_eidolons: 3356,
    current_avg_round: 3281,
    prev_avg_round: 0
  },
  {
    char: 'silver-wolf',
    name: 'Silver Wolf',
    rarity: 'Limited 5*',
    current_app_rate: 9.07,
    prev_app_rate: 7.79,
    round_eidolons: 3434,
    current_avg_round: 3355,
    prev_avg_round: 3277
  },
  {
    char: 'sparkle',
    name: 'Sparkle',
    rarity: 'Limited 5*',
    current_app_rate: 22.82,
    prev_app_rate: 18.03,
    round_eidolons: 3503,
    current_avg_round: 3302,
    prev_avg_round: 3294
  },
  {
    char: 'sunday',
    name: 'Sunday',
    rarity: 'Limited 5*',
    current_app_rate: 35.63,
    prev_app_rate: 35.63,
    round_eidolons: 3509,
    current_avg_round: 3371,
    prev_avg_round: 3371
  },
  {
    char: 'sushang',
    name: 'Sushang',
    rarity: '4*',
    current_app_rate: 0.01,
    prev_app_rate: 0.15,
    round_eidolons: 0,
    current_avg_round: 0,
    prev_avg_round: 0
  },
  {
    char: 'tingyun',
    name: 'Tingyun',
    rarity: '4*',
    current_app_rate: 3.82,
    prev_app_rate: 5.69,
    round_eidolons: 3401,
    current_avg_round: 3280,
    prev_avg_round: 3281
  },
  {
    char: 'topaz',
    name: 'Topaz & Numby',
    rarity: 'Limited 5*',
    current_app_rate: 13.28,
    prev_app_rate: 39.9,
    round_eidolons: 3573,
    current_avg_round: 3466,
    prev_avg_round: 3547
  },
  {
    char: 'trailblazer-destruction',
    name: 'Trailblazer (Destruction)',
    rarity: '4*',
    current_app_rate: 0,
    prev_app_rate: 0,
    round_eidolons: 0,
    current_avg_round: 0,
    prev_avg_round: 0
  },
  {
    char: 'trailblazer-harmony',
    name: 'Trailblazer (Harmony)',
    rarity: '4*',
    current_app_rate: 77.26,
    prev_app_rate: 85.38,
    round_eidolons: 3628,
    current_avg_round: 3518,
    prev_avg_round: 3425
  },
  {
    char: 'trailblazer-preservation',
    name: 'Trailblazer (Preservation)',
    rarity: '4*',
    current_app_rate: 0,
    prev_app_rate: 0.07,
    round_eidolons: 0,
    current_avg_round: 0,
    prev_avg_round: 0
  },
  {
    char: 'welt',
    name: 'Welt',
    rarity: 'Standard 5*',
    current_app_rate: 1.73,
    prev_app_rate: 0.64,
    round_eidolons: 3371,
    current_avg_round: 3296,
    prev_avg_round: 3233
  },
  {
    char: 'xueyi',
    name: 'Xueyi',
    rarity: '4*',
    current_app_rate: 0.61,
    prev_app_rate: 0.2,
    round_eidolons: 3294,
    current_avg_round: 3208,
    prev_avg_round: 0
  },
  {
    char: 'yanqing',
    name: 'Yanqing',
    rarity: 'Standard 5*',
    current_app_rate: 0,
    prev_app_rate: 0,
    round_eidolons: 0,
    current_avg_round: 0,
    prev_avg_round: 0
  },
  {
    char: 'yukong',
    name: 'Yukong',
    rarity: '4*',
    current_app_rate: 0.01,
    prev_app_rate: 0.09,
    round_eidolons: 0,
    current_avg_round: 0,
    prev_avg_round: 0
  },
  {
    char: 'yunli',
    name: 'Yunli',
    rarity: 'Limited 5*',
    current_app_rate: 1.58,
    prev_app_rate: 9.28,
    round_eidolons: 3304,
    current_avg_round: 3125,
    prev_avg_round: 3372
  }
];
