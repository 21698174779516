export const HSRApoHisto = [
  {
    slug: 'acheron',
    score: 3358,
    usage: 39.05,
    role: 'dps'
  },
  {
    slug: 'argenti',
    score: 3206,
    usage: 0.45,
    role: 'dps'
  },
  {
    slug: 'arlan',
    score: 3000,
    usage: 0.01,
    role: 'dps'
  },
  {
    slug: 'asta',
    score: 3284,
    usage: 0.67,
    role: 'amplifier'
  },
  {
    slug: 'aventurine',
    score: 3484,
    usage: 48.66,
    role: 'sustain'
  },
  {
    slug: 'bailu',
    score: 3340,
    usage: 1.17,
    role: 'sustain'
  },
  {
    slug: 'black-swan',
    score: 3291,
    usage: 14.14,
    role: 'specialist'
  },
  {
    slug: 'blade',
    score: 3222,
    usage: 1.93,
    role: 'dps'
  },
  {
    slug: 'boothill',
    score: 3428,
    usage: 4.58,
    role: 'specialist'
  },
  {
    slug: 'bronya',
    score: 3398,
    usage: 11.78,
    role: 'amplifier'
  },
  {
    slug: 'clara',
    score: 3182,
    usage: 1.02,
    role: 'dps'
  },
  {
    slug: 'dan-heng',
    score: 3000,
    usage: 0.03,
    role: 'dps'
  },
  {
    slug: 'dr-ratio',
    score: 3226,
    usage: 4.51,
    role: 'dps'
  },
  {
    slug: 'feixiao',
    score: 3510,
    usage: 45.97,
    role: 'dps'
  },
  {
    slug: 'firefly',
    score: 3458,
    usage: 58.36,
    role: 'specialist'
  },
  {
    slug: 'fu-xuan',
    score: 3367,
    usage: 26.64,
    role: 'sustain'
  },
  {
    slug: 'gallagher',
    score: 3436,
    usage: 60.76,
    role: 'sustain'
  },
  {
    slug: 'gepard',
    score: 3353,
    usage: 1.31,
    role: 'sustain'
  },
  {
    slug: 'guinaifen',
    score: 3312,
    usage: 1.95,
    role: 'amplifier'
  },
  {
    slug: 'hanya',
    score: 3094,
    usage: 0.13,
    role: 'amplifier'
  },
  {
    slug: 'herta',
    score: 3197,
    usage: 1.39,
    role: 'dps'
  },
  {
    slug: 'himeko',
    score: 3269,
    usage: 4.73,
    role: 'dps'
  },
  {
    slug: 'hook',
    score: 3000,
    usage: 0.04,
    role: 'dps'
  },
  {
    slug: 'huohuo',
    score: 3365,
    usage: 19.51,
    role: 'sustain'
  },
  {
    slug: 'imbibitor-lunae',
    score: 3221,
    usage: 3.76,
    role: 'dps'
  },
  {
    slug: 'jade',
    score: 3337,
    usage: 3.82,
    role: 'specialist'
  },
  {
    slug: 'jiaoqiu',
    score: 3402,
    usage: 21.64,
    role: 'amplifier'
  },
  {
    slug: 'jing-yuan',
    score: 3294,
    usage: 8.39,
    role: 'dps'
  },
  {
    slug: 'jingliu',
    score: 3158,
    usage: 5.34,
    role: 'dps'
  },
  {
    slug: 'kafka',
    score: 3305,
    usage: 14.71,
    role: 'specialist'
  },
  {
    slug: 'lingsha',
    score: 3547,
    usage: 35.67,
    role: 'sustain'
  },
  {
    slug: 'luka',
    score: 3112,
    usage: 0.11,
    role: 'specialist'
  },
  {
    slug: 'luocha',
    score: 3374,
    usage: 10.94,
    role: 'sustain'
  },
  {
    slug: 'lynx',
    score: 3336,
    usage: 0.71,
    role: 'sustain'
  },
  {
    slug: 'march-7th',
    score: 3000,
    usage: 0.08,
    role: 'sustain'
  },
  {
    slug: 'march-7th-swordmaster',
    score: 3390,
    usage: 22.1,
    role: 'specialist'
  },
  {
    slug: 'misha',
    score: 3000,
    usage: 0.08,
    role: 'dps'
  },
  {
    slug: 'moze',
    score: 3439,
    usage: 3.31,
    role: 'specialist'
  },
  {
    slug: 'natasha',
    score: 3127,
    usage: 0.13,
    role: 'sustain'
  },
  {
    slug: 'pela',
    score: 3359,
    usage: 17.86,
    role: 'amplifier'
  },
  {
    slug: 'qingque',
    score: 3090,
    usage: 0.67,
    role: 'dps'
  },
  {
    slug: 'rappa',
    score: 3488,
    usage: 15.06,
    role: 'specialist',
    new: true
  },
  {
    slug: 'robin',
    score: 3468,
    usage: 60.8,
    role: 'amplifier'
  },
  {
    slug: 'ruan-mei',
    score: 3450,
    usage: 85.36,
    role: 'amplifier'
  },
  {
    slug: 'sampo',
    score: 3098,
    usage: 0.34,
    role: 'specialist'
  },
  {
    slug: 'seele',
    score: 3191,
    usage: 1.11,
    role: 'dps'
  },
  {
    slug: 'serval',
    score: 3094,
    usage: 0.36,
    role: 'dps'
  },
  {
    slug: 'silver-wolf',
    score: 3309,
    usage: 8.24,
    role: 'amplifier'
  },
  {
    slug: 'sparkle',
    score: 3306,
    usage: 22.34,
    role: 'amplifier'
  },
  {
    slug: 'sunday',
    score: 3371,
    usage: 35.63,
    role: 'amplifier',
    new: true
  },
  {
    slug: 'sushang',
    score: 3000,
    usage: 0.13,
    role: 'specialist'
  },
  {
    slug: 'tingyun',
    score: 3294,
    usage: 6.51,
    role: 'amplifier'
  },
  {
    slug: 'topaz',
    score: 3506,
    usage: 26.08,
    role: 'specialist'
  },
  {
    slug: 'trailblazer-destruction',
    score: 3000,
    usage: 0.0,
    role: 'dps'
  },
  {
    slug: 'trailblazer-harmony',
    score: 3451,
    usage: 72.56,
    role: 'amplifier'
  },
  {
    slug: 'trailblazer-preservation',
    score: 3000,
    usage: 0.05,
    role: 'sustain'
  },
  {
    slug: 'welt',
    score: 3176,
    usage: 0.88,
    role: 'specialist'
  },
  {
    slug: 'xueyi',
    score: 3069,
    usage: 0.28,
    role: 'specialist'
  },
  {
    slug: 'yanqing',
    score: 3093,
    usage: 0.24,
    role: 'dps'
  },
  {
    slug: 'yukong',
    score: 3000,
    usage: 0.08,
    role: 'amplifier'
  },
  {
    slug: 'yunli',
    score: 3287,
    usage: 6.47,
    role: 'dps'
  }
];
