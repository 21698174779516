export const ApoCurrentStage4Side2 = [
  {
    char_one: 'jing-yuan',
    char_two: 'Robin',
    char_three: 'Sunday',
    char_four: 'Aventurine',
    app_rate: 6.44,
    rank: 1,
    avg_round: 3396,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Pela',
    char_four: 'Aventurine',
    app_rate: 5.36,
    rank: 2,
    avg_round: 3559,
    star_num: '4'
  },
  {
    char_one: 'Rappa',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'Gallagher',
    app_rate: 5.08,
    rank: 3,
    avg_round: 3422,
    star_num: '4'
  },
  {
    char_one: 'Rappa',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'Lingsha',
    app_rate: 4.64,
    rank: 4,
    avg_round: 3565,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'Lingsha',
    app_rate: 4.36,
    rank: 5,
    avg_round: 3321,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Sparkle',
    char_four: 'Aventurine',
    app_rate: 3.72,
    rank: 6,
    avg_round: 3430,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'Robin',
    char_three: 'Sunday',
    char_four: 'Huohuo',
    app_rate: 2.44,
    rank: 7,
    avg_round: 3426,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Sunday',
    char_four: 'Aventurine',
    app_rate: 2.37,
    rank: 8,
    avg_round: 3501,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'Sparkle',
    char_four: 'Aventurine',
    app_rate: 2.35,
    rank: 9,
    avg_round: 3403,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'silver-wolf',
    char_four: 'Aventurine',
    app_rate: 2.26,
    rank: 10,
    avg_round: 3402,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'Gallagher',
    app_rate: 1.95,
    rank: 11,
    avg_round: 3139,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Pela',
    char_four: 'fu-xuan',
    app_rate: 1.82,
    rank: 12,
    avg_round: 3510,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'black-swan',
    char_four: 'Aventurine',
    app_rate: 1.78,
    rank: 13,
    avg_round: 3340,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'Sunday',
    char_four: 'Aventurine',
    app_rate: 1.69,
    rank: 14,
    avg_round: 3380,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 1.46,
    rank: 15,
    avg_round: 3414,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'Robin',
    char_three: 'Sunday',
    char_four: 'Luocha',
    app_rate: 1.14,
    rank: 16,
    avg_round: 3449,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Pela',
    char_four: 'Gallagher',
    app_rate: 1.11,
    rank: 17,
    avg_round: 3534,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'topaz',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 1.03,
    rank: 18,
    avg_round: 3201,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'Pela',
    char_four: 'Aventurine',
    app_rate: 0.94,
    rank: 19,
    avg_round: 3377,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.88,
    rank: 20,
    avg_round: 3334,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'black-swan',
    char_four: 'fu-xuan',
    app_rate: 0.82,
    rank: 21,
    avg_round: 3301,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'Robin',
    char_three: 'Sunday',
    char_four: 'fu-xuan',
    app_rate: 0.81,
    rank: 22,
    avg_round: 3312,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'Sunday',
    char_three: 'Tingyun',
    char_four: 'Aventurine',
    app_rate: 0.78,
    rank: 23,
    avg_round: 3344,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'black-swan',
    char_three: 'Pela',
    char_four: 'Aventurine',
    app_rate: 0.77,
    rank: 24,
    avg_round: 3360,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'silver-wolf',
    char_four: 'fu-xuan',
    app_rate: 0.73,
    rank: 25,
    avg_round: 3384,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Guinaifen',
    char_three: 'Pela',
    char_four: 'Aventurine',
    app_rate: 0.61,
    rank: 26,
    avg_round: 3398,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'Sparkle',
    char_three: 'Sunday',
    char_four: 'Aventurine',
    app_rate: 0.6,
    rank: 27,
    avg_round: 3367,
    star_num: '4'
  },
  {
    char_one: 'Rappa',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'Aventurine',
    app_rate: 0.58,
    rank: 28,
    avg_round: 3465,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'Sparkle',
    char_three: 'Sunday',
    char_four: 'Huohuo',
    app_rate: 0.56,
    rank: 29,
    avg_round: 3337,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.56,
    rank: 29,
    avg_round: 3417,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.56,
    rank: 29,
    avg_round: 3095,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'Robin',
    char_three: 'Sunday',
    char_four: 'Gallagher',
    app_rate: 0.53,
    rank: 32,
    avg_round: 3428,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'Sparkle',
    char_three: 'Sunday',
    char_four: 'fu-xuan',
    app_rate: 0.51,
    rank: 33,
    avg_round: 3301,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'Pela',
    char_four: 'fu-xuan',
    app_rate: 0.49,
    rank: 34,
    avg_round: 3332,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Pela',
    char_four: 'Luocha',
    app_rate: 0.45,
    rank: 35,
    avg_round: 3513,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Welt',
    char_three: 'Pela',
    char_four: 'Aventurine',
    app_rate: 0.45,
    rank: 35,
    avg_round: 3367,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Bronya',
    char_three: 'Pela',
    char_four: 'Aventurine',
    app_rate: 0.45,
    rank: 35,
    avg_round: 3320,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Robin',
    char_three: 'Sunday',
    char_four: 'Lingsha',
    app_rate: 0.44,
    rank: 38,
    avg_round: 3607,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.44,
    rank: 38,
    avg_round: 3386,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Sparkle',
    char_four: 'Gallagher',
    app_rate: 0.41,
    rank: 40,
    avg_round: 3415,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Sunday',
    char_four: 'fu-xuan',
    app_rate: 0.4,
    rank: 41,
    avg_round: 3449,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Bronya',
    char_three: 'Jiaoqiu',
    char_four: 'Aventurine',
    app_rate: 0.39,
    rank: 42,
    avg_round: 3364,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'Sparkle',
    char_three: 'Sunday',
    char_four: 'Aventurine',
    app_rate: 0.38,
    rank: 43,
    avg_round: 3333,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'silver-wolf',
    char_three: 'Sparkle',
    char_four: 'Aventurine',
    app_rate: 0.38,
    rank: 43,
    avg_round: 3165,
    star_num: '4'
  },
  {
    char_one: 'Rappa',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'Luocha',
    app_rate: 0.38,
    rank: 43,
    avg_round: 3366,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'ruan-mei',
    char_three: 'Sunday',
    char_four: 'Huohuo',
    app_rate: 0.37,
    rank: 46,
    avg_round: 3348,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'Jiaoqiu',
    char_four: 'Aventurine',
    app_rate: 0.36,
    rank: 47,
    avg_round: 3398,
    star_num: '4'
  },
  {
    char_one: 'Rappa',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'Huohuo',
    app_rate: 0.34,
    rank: 48,
    avg_round: 3372,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'Sunday',
    char_three: 'Tingyun',
    char_four: 'Huohuo',
    app_rate: 0.31,
    rank: 49,
    avg_round: 3326,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Guinaifen',
    char_three: 'Pela',
    char_four: 'fu-xuan',
    app_rate: 0.31,
    rank: 49,
    avg_round: 3365,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Sunday',
    char_four: 'Gallagher',
    app_rate: 0.31,
    rank: 49,
    avg_round: 3508,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'black-swan',
    char_three: 'Jiaoqiu',
    char_four: 'Aventurine',
    app_rate: 0.3,
    rank: 52,
    avg_round: 3372,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'Sunday',
    char_four: 'fu-xuan',
    app_rate: 0.3,
    rank: 52,
    avg_round: 3317,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'Sparkle',
    char_four: 'Gallagher',
    app_rate: 0.3,
    rank: 52,
    avg_round: 3173,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'ruan-mei',
    char_three: 'Sunday',
    char_four: 'Gallagher',
    app_rate: 0.3,
    rank: 52,
    avg_round: 3301,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'black-swan',
    char_four: 'Huohuo',
    app_rate: 0.29,
    rank: 56,
    avg_round: 3258,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'ruan-mei',
    char_three: 'Sunday',
    char_four: 'Luocha',
    app_rate: 0.29,
    rank: 56,
    avg_round: 3246,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Pela',
    char_four: 'Lingsha',
    app_rate: 0.29,
    rank: 56,
    avg_round: 3564,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'Robin',
    char_three: 'Sunday',
    char_four: 'Lingsha',
    app_rate: 0.29,
    rank: 56,
    avg_round: 3507,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Pela',
    char_four: 'Huohuo',
    app_rate: 0.28,
    rank: 60,
    avg_round: 3504,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Herta',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.28,
    rank: 60,
    avg_round: 3219,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Guinaifen',
    char_three: 'Pela',
    char_four: 'Gallagher',
    app_rate: 0.26,
    rank: 62,
    avg_round: 3384,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'Sparkle',
    char_four: 'Lingsha',
    app_rate: 0.26,
    rank: 62,
    avg_round: 3354,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'Sparkle',
    char_three: 'Sunday',
    char_four: 'Luocha',
    app_rate: 0.26,
    rank: 62,
    avg_round: 3314,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Welt',
    char_three: 'Pela',
    char_four: 'fu-xuan',
    app_rate: 0.26,
    rank: 62,
    avg_round: 3233,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'silver-wolf',
    char_four: 'Aventurine',
    app_rate: 0.25,
    rank: 66,
    avg_round: 3421,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'Sparkle',
    char_three: 'Tingyun',
    char_four: 'Aventurine',
    app_rate: 0.25,
    rank: 66,
    avg_round: 3061,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'black-swan',
    char_four: 'Gallagher',
    app_rate: 0.25,
    rank: 66,
    avg_round: 3306,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Sparkle',
    char_four: 'Lingsha',
    app_rate: 0.25,
    rank: 66,
    avg_round: 3372,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'Sunday',
    char_four: 'Gallagher',
    app_rate: 0.23,
    rank: 70,
    avg_round: 3256,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Sparkle',
    char_four: 'Luocha',
    app_rate: 0.23,
    rank: 70,
    avg_round: 3206,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'black-swan',
    char_four: 'Luocha',
    app_rate: 0.23,
    rank: 70,
    avg_round: 3266,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'ruan-mei',
    char_three: 'Sunday',
    char_four: 'Aventurine',
    app_rate: 0.22,
    rank: 73,
    avg_round: 3436,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'black-swan',
    char_three: 'Pela',
    char_four: 'fu-xuan',
    app_rate: 0.21,
    rank: 74,
    avg_round: 3299,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'Sunday',
    char_three: 'Tingyun',
    char_four: 'Luocha',
    app_rate: 0.2,
    rank: 75,
    avg_round: 3371,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'Sunday',
    char_three: 'Tingyun',
    char_four: 'Gallagher',
    app_rate: 0.2,
    rank: 75,
    avg_round: 3408,
    star_num: '4'
  },
  {
    char_one: 'Rappa',
    char_two: 'trailblazer-harmony',
    char_three: 'Pela',
    char_four: 'Gallagher',
    app_rate: 0.2,
    rank: 75,
    avg_round: 3536,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'silver-wolf',
    char_four: 'Gallagher',
    app_rate: 0.19,
    rank: 78,
    avg_round: 3265,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'Sparkle',
    char_three: 'Sunday',
    char_four: 'Luocha',
    app_rate: 0.18,
    rank: 79,
    avg_round: 3301,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Bronya',
    char_three: 'Pela',
    char_four: 'Gallagher',
    app_rate: 0.18,
    rank: 79,
    avg_round: 3111,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'black-swan',
    char_three: 'Sparkle',
    char_four: 'Aventurine',
    app_rate: 0.18,
    rank: 79,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'black-swan',
    char_four: 'Lingsha',
    app_rate: 0.17,
    rank: 82,
    avg_round: 3295,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'Pela',
    char_four: 'Gallagher',
    app_rate: 0.17,
    rank: 82,
    avg_round: 3357,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Sunday',
    char_four: 'Lingsha',
    app_rate: 0.17,
    rank: 82,
    avg_round: 3569,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'Pela',
    char_four: 'Huohuo',
    app_rate: 0.17,
    rank: 82,
    avg_round: 3206,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'Sparkle',
    char_four: 'Luocha',
    app_rate: 0.17,
    rank: 82,
    avg_round: 3144,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'Sunday',
    char_three: 'Tingyun',
    char_four: 'fu-xuan',
    app_rate: 0.17,
    rank: 82,
    avg_round: 3261,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'silver-wolf',
    char_four: 'Lingsha',
    app_rate: 0.16,
    rank: 88,
    avg_round: 3456,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'Pela',
    char_three: 'Sunday',
    char_four: 'Aventurine',
    app_rate: 0.16,
    rank: 88,
    avg_round: 3264,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'Robin',
    char_three: 'Sunday',
    char_four: 'Aventurine',
    app_rate: 0.16,
    rank: 88,
    avg_round: 3280,
    star_num: '4'
  },
  {
    char_one: 'trailblazer-harmony',
    char_two: 'ruan-mei',
    char_three: 'Sunday',
    char_four: 'Lingsha',
    app_rate: 0.16,
    rank: 88,
    avg_round: 3372,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'Lingsha',
    app_rate: 0.16,
    rank: 88,
    avg_round: 3246,
    star_num: '4'
  },
  {
    char_one: 'Rappa',
    char_two: 'Himeko',
    char_three: 'trailblazer-harmony',
    char_four: 'Gallagher',
    app_rate: 0.15,
    rank: 93,
    avg_round: 3369,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'black-swan',
    char_three: 'Jiaoqiu',
    char_four: 'fu-xuan',
    app_rate: 0.15,
    rank: 93,
    avg_round: 3334,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'Sunday',
    char_four: 'Lingsha',
    app_rate: 0.15,
    rank: 93,
    avg_round: 3333,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Guinaifen',
    char_three: 'Pela',
    char_four: 'Lingsha',
    app_rate: 0.15,
    rank: 93,
    avg_round: 3443,
    star_num: '4'
  },
  {
    char_one: 'Serval',
    char_two: 'Robin',
    char_three: 'Sunday',
    char_four: 'Aventurine',
    app_rate: 0.13,
    rank: 97,
    avg_round: 3335,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'silver-wolf',
    char_three: 'Sunday',
    char_four: 'Aventurine',
    app_rate: 0.13,
    rank: 97,
    avg_round: 3214,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Robin',
    char_four: 'fu-xuan',
    app_rate: 0.13,
    rank: 97,
    avg_round: 3379,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'topaz',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.13,
    rank: 97,
    avg_round: 3037,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'black-swan',
    char_three: 'Sunday',
    char_four: 'Aventurine',
    app_rate: 0.13,
    rank: 97,
    avg_round: 3402,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'Bronya',
    char_three: 'Sunday',
    char_four: 'Luocha',
    app_rate: 0.13,
    rank: 97,
    avg_round: 3392,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Robin',
    char_four: 'Gallagher',
    app_rate: 0.13,
    rank: 97,
    avg_round: 3521,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'Jade',
    char_three: 'Sunday',
    char_four: 'Aventurine',
    app_rate: 0.13,
    rank: 97,
    avg_round: 3324,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'Jiaoqiu',
    char_three: 'Sunday',
    char_four: 'Aventurine',
    app_rate: 0.12,
    rank: 105,
    avg_round: 3422,
    star_num: '4'
  },
  {
    char_one: 'Rappa',
    char_two: 'Firefly',
    char_three: 'trailblazer-harmony',
    char_four: 'Gallagher',
    app_rate: 0.12,
    rank: 105,
    avg_round: 3372,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Sparkle',
    char_four: 'Huohuo',
    app_rate: 0.12,
    rank: 105,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Bronya',
    char_three: 'Pela',
    char_four: 'fu-xuan',
    app_rate: 0.12,
    rank: 105,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Sunday',
    char_four: 'Luocha',
    app_rate: 0.12,
    rank: 105,
    avg_round: 3470,
    star_num: '4'
  },
  {
    char_one: 'Rappa',
    char_two: 'trailblazer-harmony',
    char_three: 'Pela',
    char_four: 'Lingsha',
    app_rate: 0.11,
    rank: 110,
    avg_round: 3666,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Bronya',
    char_three: 'Jiaoqiu',
    char_four: 'Gallagher',
    app_rate: 0.11,
    rank: 110,
    avg_round: 3522,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'black-swan',
    char_three: 'Pela',
    char_four: 'Gallagher',
    app_rate: 0.11,
    rank: 110,
    avg_round: 3336,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Pela',
    char_four: 'Gepard',
    app_rate: 0.11,
    rank: 110,
    avg_round: 3565,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'Robin',
    char_three: 'Sparkle',
    char_four: 'Aventurine',
    app_rate: 0.11,
    rank: 110,
    avg_round: 3190,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Feixiao',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.11,
    rank: 110,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'Sparkle',
    char_three: 'Sunday',
    char_four: 'Gallagher',
    app_rate: 0.11,
    rank: 110,
    avg_round: 3433,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'Sparkle',
    char_four: 'Aventurine',
    app_rate: 0.11,
    rank: 110,
    avg_round: 3358,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'Bronya',
    char_three: 'Sunday',
    char_four: 'Aventurine',
    app_rate: 0.11,
    rank: 110,
    avg_round: 3339,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'Pela',
    char_four: 'Lingsha',
    app_rate: 0.11,
    rank: 110,
    avg_round: 3299,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'Sparkle',
    char_three: 'Sunday',
    char_four: 'Huohuo',
    app_rate: 0.11,
    rank: 110,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.1,
    rank: 121,
    avg_round: 3202,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Bronya',
    char_three: 'Jiaoqiu',
    char_four: 'fu-xuan',
    app_rate: 0.1,
    rank: 121,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'silver-wolf',
    char_four: 'fu-xuan',
    app_rate: 0.1,
    rank: 121,
    avg_round: 3361,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'Pela',
    char_four: 'Luocha',
    app_rate: 0.1,
    rank: 121,
    avg_round: 3207,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Welt',
    char_three: 'Pela',
    char_four: 'Luocha',
    app_rate: 0.1,
    rank: 121,
    avg_round: 3205,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Welt',
    char_three: 'Pela',
    char_four: 'Gallagher',
    app_rate: 0.1,
    rank: 121,
    avg_round: 3306,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'Jade',
    char_three: 'Sunday',
    char_four: 'Huohuo',
    app_rate: 0.09,
    rank: 127,
    avg_round: 3478,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'ruan-mei',
    char_three: 'Sparkle',
    char_four: 'Luocha',
    app_rate: 0.09,
    rank: 127,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'ruan-mei',
    char_four: 'Gallagher',
    app_rate: 0.09,
    rank: 127,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'silver-wolf',
    char_four: 'Luocha',
    app_rate: 0.09,
    rank: 127,
    avg_round: 3356,
    star_num: '4'
  },
  {
    char_one: 'Jingliu',
    char_two: 'Robin',
    char_three: 'Sunday',
    char_four: 'Aventurine',
    app_rate: 0.09,
    rank: 127,
    avg_round: 3336,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'Robin',
    char_four: 'Gallagher',
    app_rate: 0.09,
    rank: 127,
    avg_round: 3116,
    star_num: '4'
  },
  {
    char_one: 'Rappa',
    char_two: 'trailblazer-harmony',
    char_three: 'Pela',
    char_four: 'Aventurine',
    app_rate: 0.09,
    rank: 127,
    avg_round: 3522,
    star_num: '4'
  },
  {
    char_one: 'Rappa',
    char_two: 'trailblazer-harmony',
    char_three: 'Sunday',
    char_four: 'Lingsha',
    app_rate: 0.09,
    rank: 127,
    avg_round: 3541,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'black-swan',
    char_three: 'Pela',
    char_four: 'Luocha',
    app_rate: 0.09,
    rank: 127,
    avg_round: 3179,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Bronya',
    char_three: 'Pela',
    char_four: 'Luocha',
    app_rate: 0.08,
    rank: 136,
    avg_round: 3322,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'Robin',
    char_four: 'Lingsha',
    app_rate: 0.08,
    rank: 136,
    avg_round: 3109,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Robin',
    char_four: 'Sparkle',
    app_rate: 0.08,
    rank: 136,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'Sunday',
    char_four: 'Aventurine',
    app_rate: 0.08,
    rank: 136,
    avg_round: 3297,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'Sunday',
    char_four: 'Luocha',
    app_rate: 0.08,
    rank: 136,
    avg_round: 3258,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'black-swan',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.08,
    rank: 136,
    avg_round: 3188,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Robin',
    char_three: 'Sunday',
    char_four: 'Huohuo',
    app_rate: 0.08,
    rank: 136,
    avg_round: 3035,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'ruan-mei',
    char_four: 'Gallagher',
    app_rate: 0.08,
    rank: 136,
    avg_round: 3180,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Robin',
    char_four: 'Luocha',
    app_rate: 0.08,
    rank: 136,
    avg_round: 3448,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'ruan-mei',
    char_three: 'Sunday',
    char_four: 'fu-xuan',
    app_rate: 0.08,
    rank: 136,
    avg_round: 3329,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'Jiaoqiu',
    char_four: 'fu-xuan',
    app_rate: 0.08,
    rank: 136,
    avg_round: 3500,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Bronya',
    char_three: 'Jiaoqiu',
    char_four: 'Luocha',
    app_rate: 0.08,
    rank: 136,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Himeko',
    char_three: 'Pela',
    char_four: 'Aventurine',
    app_rate: 0.08,
    rank: 136,
    avg_round: 3278,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Bronya',
    char_three: 'silver-wolf',
    char_four: 'Aventurine',
    app_rate: 0.07,
    rank: 149,
    avg_round: 3283,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'Bronya',
    char_three: 'Sunday',
    char_four: 'fu-xuan',
    app_rate: 0.07,
    rank: 149,
    avg_round: 3310,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'Sparkle',
    char_three: 'Sunday',
    char_four: 'Gallagher',
    app_rate: 0.07,
    rank: 149,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Jingliu',
    char_two: 'Robin',
    char_three: 'Sunday',
    char_four: 'Luocha',
    app_rate: 0.07,
    rank: 149,
    avg_round: 3256,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'ruan-mei',
    char_four: 'Aventurine',
    app_rate: 0.07,
    rank: 149,
    avg_round: 3200,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Sparkle',
    char_three: 'Sunday',
    char_four: 'Aventurine',
    app_rate: 0.07,
    rank: 149,
    avg_round: 3340,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'Jade',
    char_three: 'Sunday',
    char_four: 'Luocha',
    app_rate: 0.07,
    rank: 149,
    avg_round: 3440,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'ruan-mei',
    char_three: 'Sunday',
    char_four: 'Huohuo',
    app_rate: 0.07,
    rank: 149,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Welt',
    char_three: 'Pela',
    char_four: 'Gepard',
    app_rate: 0.07,
    rank: 149,
    avg_round: 3314,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'Sunday',
    char_three: 'Tingyun',
    char_four: 'Aventurine',
    app_rate: 0.07,
    rank: 149,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Yunli',
    char_three: 'Pela',
    char_four: 'fu-xuan',
    app_rate: 0.07,
    rank: 149,
    avg_round: 3204,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'Bronya',
    char_three: 'Robin',
    char_four: 'Sunday',
    app_rate: 0.07,
    rank: 149,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Sunday',
    char_four: 'Huohuo',
    app_rate: 0.07,
    rank: 149,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Robin',
    char_three: 'Tingyun',
    char_four: 'Huohuo',
    app_rate: 0.06,
    rank: 162,
    avg_round: 3082,
    star_num: '4'
  },
  {
    char_one: 'Rappa',
    char_two: 'Firefly',
    char_three: 'trailblazer-harmony',
    char_four: 'Lingsha',
    app_rate: 0.06,
    rank: 162,
    avg_round: 3458,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'black-swan',
    char_three: 'Jiaoqiu',
    char_four: 'Luocha',
    app_rate: 0.06,
    rank: 162,
    avg_round: 3461,
    star_num: '4'
  },
  {
    char_one: 'Serval',
    char_two: 'Robin',
    char_three: 'Sunday',
    char_four: 'Gallagher',
    app_rate: 0.06,
    rank: 162,
    avg_round: 3344,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'black-swan',
    char_three: 'silver-wolf',
    char_four: 'Aventurine',
    app_rate: 0.06,
    rank: 162,
    avg_round: 3334,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'Jade',
    char_three: 'Sunday',
    char_four: 'Gallagher',
    app_rate: 0.06,
    rank: 162,
    avg_round: 3443,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'Aventurine',
    app_rate: 0.06,
    rank: 162,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Welt',
    char_three: 'Jiaoqiu',
    char_four: 'Aventurine',
    app_rate: 0.06,
    rank: 162,
    avg_round: 3459,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'Bronya',
    char_three: 'Sunday',
    char_four: 'Gallagher',
    app_rate: 0.06,
    rank: 162,
    avg_round: 3392,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Guinaifen',
    char_three: 'Jiaoqiu',
    char_four: 'Aventurine',
    app_rate: 0.06,
    rank: 162,
    avg_round: 3451,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'ruan-mei',
    char_four: 'Huohuo',
    app_rate: 0.06,
    rank: 162,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'Lingsha',
    app_rate: 0.06,
    rank: 162,
    avg_round: 3527,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'ruan-mei',
    char_three: 'Sunday',
    char_four: 'Aventurine',
    app_rate: 0.06,
    rank: 162,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'black-swan',
    char_three: 'Jiaoqiu',
    char_four: 'Gallagher',
    app_rate: 0.06,
    rank: 162,
    avg_round: 3404,
    star_num: '4'
  },
  {
    char_one: 'Bronya',
    char_two: 'Robin',
    char_three: 'Sunday',
    char_four: 'Lingsha',
    app_rate: 0.06,
    rank: 162,
    avg_round: 3334,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Guinaifen',
    char_three: 'Sunday',
    char_four: 'Aventurine',
    app_rate: 0.06,
    rank: 162,
    avg_round: 3296,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'Jiaoqiu',
    char_four: 'Gallagher',
    app_rate: 0.06,
    rank: 162,
    avg_round: 3421,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'Sparkle',
    char_three: 'Tingyun',
    char_four: 'fu-xuan',
    app_rate: 0.06,
    rank: 162,
    avg_round: 3150,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'Robin',
    char_three: 'Sunday',
    char_four: 'Bailu',
    app_rate: 0.06,
    rank: 162,
    avg_round: 3257,
    star_num: '4'
  },
  {
    char_one: 'Serval',
    char_two: 'Robin',
    char_three: 'Sunday',
    char_four: 'Huohuo',
    app_rate: 0.06,
    rank: 162,
    avg_round: 3327,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'ruan-mei',
    char_four: 'Lingsha',
    app_rate: 0.06,
    rank: 162,
    avg_round: 3497,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'Robin',
    char_four: 'fu-xuan',
    app_rate: 0.06,
    rank: 162,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'Sparkle',
    char_four: 'Huohuo',
    app_rate: 0.06,
    rank: 162,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Herta',
    char_two: 'Robin',
    char_three: 'Sunday',
    char_four: 'Aventurine',
    app_rate: 0.06,
    rank: 162,
    avg_round: 3327,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'silver-wolf',
    char_four: 'Aventurine',
    app_rate: 0.06,
    rank: 162,
    avg_round: 3195,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Himeko',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.06,
    rank: 162,
    avg_round: 3191,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'Robin',
    char_three: 'Sunday',
    char_four: 'Aventurine',
    app_rate: 0.06,
    rank: 162,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Herta',
    char_three: 'Robin',
    char_four: 'Lingsha',
    app_rate: 0.06,
    rank: 162,
    avg_round: 3153,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Himeko',
    char_three: 'Jiaoqiu',
    char_four: 'Pela',
    app_rate: 0.06,
    rank: 162,
    avg_round: 3734,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'Robin',
    char_three: 'Sparkle',
    char_four: 'Aventurine',
    app_rate: 0.06,
    rank: 162,
    avg_round: 3222,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'ruan-mei',
    char_three: 'Sparkle',
    char_four: 'Huohuo',
    app_rate: 0.06,
    rank: 162,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Welt',
    char_three: 'Sparkle',
    char_four: 'Aventurine',
    app_rate: 0.06,
    rank: 162,
    avg_round: 3295,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'ruan-mei',
    char_four: 'Aventurine',
    app_rate: 0.06,
    rank: 162,
    avg_round: 3377,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'black-swan',
    char_four: 'Gepard',
    app_rate: 0.06,
    rank: 162,
    avg_round: 3307,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'Robin',
    char_three: 'Sunday',
    char_four: 'Luocha',
    app_rate: 0.06,
    rank: 162,
    avg_round: 3140,
    star_num: '4'
  },
  {
    char_one: 'Rappa',
    char_two: 'Bronya',
    char_three: 'trailblazer-harmony',
    char_four: 'Gallagher',
    app_rate: 0.06,
    rank: 162,
    avg_round: 3404,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'Robin',
    char_three: 'Sunday',
    char_four: 'Huohuo',
    app_rate: 0.06,
    rank: 162,
    avg_round: 3476,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'Jade',
    char_three: 'Robin',
    char_four: 'Sunday',
    app_rate: 0.06,
    rank: 162,
    avg_round: 3706,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Guinaifen',
    char_three: 'Pela',
    char_four: 'Luocha',
    app_rate: 0.06,
    rank: 162,
    avg_round: 3351,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'Pela',
    char_three: 'Sunday',
    char_four: 'Luocha',
    app_rate: 0.06,
    rank: 162,
    avg_round: 3387,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'Robin',
    char_three: 'Sunday',
    char_four: 'Gepard',
    app_rate: 0.04,
    rank: 202,
    avg_round: 3444,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Robin',
    char_four: 'Sunday',
    app_rate: 0.04,
    rank: 202,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Robin',
    char_three: 'Sunday',
    char_four: 'Aventurine',
    app_rate: 0.04,
    rank: 202,
    avg_round: 3062,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'Sunday',
    char_four: 'Huohuo',
    app_rate: 0.04,
    rank: 202,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'Sunday',
    char_three: 'Tingyun',
    char_four: 'Lingsha',
    app_rate: 0.04,
    rank: 202,
    avg_round: 3350,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'ruan-mei',
    char_three: 'Sparkle',
    char_four: 'Aventurine',
    app_rate: 0.04,
    rank: 202,
    avg_round: 3069,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'trailblazer-harmony',
    char_three: 'Pela',
    char_four: 'Gallagher',
    app_rate: 0.04,
    rank: 202,
    avg_round: 2990,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'silver-wolf',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.04,
    rank: 202,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'Jade',
    char_three: 'Sunday',
    char_four: 'fu-xuan',
    app_rate: 0.04,
    rank: 202,
    avg_round: 3349,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Sunday',
    char_three: 'Tingyun',
    char_four: 'Huohuo',
    app_rate: 0.04,
    rank: 202,
    avg_round: 3036,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'Moze',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.04,
    rank: 202,
    avg_round: 3084,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'ruan-mei',
    char_four: 'Luocha',
    app_rate: 0.04,
    rank: 202,
    avg_round: 3409,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Herta',
    char_three: 'Robin',
    char_four: 'Luocha',
    app_rate: 0.04,
    rank: 202,
    avg_round: 3095,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'Jiaoqiu',
    char_four: 'Luocha',
    app_rate: 0.04,
    rank: 202,
    avg_round: 3352,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Robin',
    char_four: 'Lingsha',
    app_rate: 0.04,
    rank: 202,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Himeko',
    char_three: 'Pela',
    char_four: 'Gallagher',
    app_rate: 0.04,
    rank: 202,
    avg_round: 3174,
    star_num: '4'
  },
  {
    char_one: 'Robin',
    char_two: 'Sparkle',
    char_three: 'Sunday',
    char_four: 'Lingsha',
    app_rate: 0.04,
    rank: 202,
    avg_round: 3583,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.04,
    rank: 202,
    avg_round: 3245,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'Sparkle',
    char_three: 'Sunday',
    char_four: 'Huohuo',
    app_rate: 0.04,
    rank: 202,
    avg_round: 3344,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'Jiaoqiu',
    char_three: 'Sunday',
    char_four: 'Luocha',
    app_rate: 0.04,
    rank: 202,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'Sparkle',
    char_three: 'Tingyun',
    char_four: 'Aventurine',
    app_rate: 0.04,
    rank: 202,
    avg_round: 3105,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'silver-wolf',
    char_four: 'Gepard',
    app_rate: 0.04,
    rank: 202,
    avg_round: 3394,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'silver-wolf',
    char_four: 'Gallagher',
    app_rate: 0.04,
    rank: 202,
    avg_round: 3426,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'Pela',
    char_three: 'Sunday',
    char_four: 'Aventurine',
    app_rate: 0.04,
    rank: 202,
    avg_round: 3246,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'ruan-mei',
    char_three: 'Sunday',
    char_four: 'Gallagher',
    app_rate: 0.04,
    rank: 202,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'ruan-mei',
    char_three: 'Sunday',
    char_four: 'Lingsha',
    app_rate: 0.04,
    rank: 202,
    avg_round: 3564,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'Pela',
    char_four: 'Gepard',
    app_rate: 0.04,
    rank: 202,
    avg_round: 3363,
    star_num: '4'
  },
  {
    char_one: 'Rappa',
    char_two: 'Acheron',
    char_three: 'Pela',
    char_four: 'Aventurine',
    app_rate: 0.04,
    rank: 202,
    avg_round: 3464,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Acheron',
    char_three: 'Pela',
    char_four: 'fu-xuan',
    app_rate: 0.04,
    rank: 202,
    avg_round: 3231,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'black-swan',
    char_three: 'Pela',
    char_four: 'Huohuo',
    app_rate: 0.04,
    rank: 202,
    avg_round: 3201,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Blade',
    char_three: 'Robin',
    char_four: 'Luocha',
    app_rate: 0.04,
    rank: 202,
    avg_round: 3147,
    star_num: '4'
  },
  {
    char_one: 'Jingliu',
    char_two: 'Pela',
    char_three: 'Sunday',
    char_four: 'Aventurine',
    app_rate: 0.04,
    rank: 202,
    avg_round: 3072,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'topaz',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.04,
    rank: 202,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'march-7th-swordmaster',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'Lingsha',
    app_rate: 0.04,
    rank: 202,
    avg_round: 3285,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Yunli',
    char_three: 'Pela',
    char_four: 'Aventurine',
    app_rate: 0.04,
    rank: 202,
    avg_round: 3312,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'Sunday',
    char_three: 'Tingyun',
    char_four: 'Huohuo',
    app_rate: 0.04,
    rank: 202,
    avg_round: 3177,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Herta',
    char_three: 'Robin',
    char_four: 'fu-xuan',
    app_rate: 0.04,
    rank: 202,
    avg_round: 2871,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'topaz',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.04,
    rank: 202,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'Luocha',
    app_rate: 0.04,
    rank: 202,
    avg_round: 3005,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'black-swan',
    char_three: 'Jiaoqiu',
    char_four: 'Huohuo',
    app_rate: 0.04,
    rank: 202,
    avg_round: 3272,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Acheron',
    char_three: 'Pela',
    char_four: 'Gallagher',
    app_rate: 0.04,
    rank: 202,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'Himeko',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.04,
    rank: 202,
    avg_round: 3106,
    star_num: '4'
  },
  {
    char_one: 'Jingliu',
    char_two: 'Bronya',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.03,
    rank: 244,
    avg_round: 2963,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'Yunli',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.03,
    rank: 244,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'Bronya',
    char_four: 'Aventurine',
    app_rate: 0.03,
    rank: 244,
    avg_round: 3246,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'ruan-mei',
    char_four: 'Aventurine',
    app_rate: 0.03,
    rank: 244,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Robin',
    char_three: 'Sunday',
    char_four: 'Gallagher',
    app_rate: 0.03,
    rank: 244,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'Sunday',
    char_three: 'Tingyun',
    char_four: 'Aventurine',
    app_rate: 0.03,
    rank: 244,
    avg_round: 3188,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Sparkle',
    char_three: 'Tingyun',
    char_four: 'Huohuo',
    app_rate: 0.03,
    rank: 244,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Herta',
    char_three: 'Robin',
    char_four: 'Gallagher',
    app_rate: 0.03,
    rank: 244,
    avg_round: 3127,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Yunli',
    char_three: 'Robin',
    char_four: 'Huohuo',
    app_rate: 0.03,
    rank: 244,
    avg_round: 3202,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'Jiaoqiu',
    char_three: 'Sunday',
    char_four: 'Gallagher',
    app_rate: 0.03,
    rank: 244,
    avg_round: 3490,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Robin',
    char_three: 'Sunday',
    char_four: 'Aventurine',
    app_rate: 0.03,
    rank: 244,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'Pela',
    char_three: 'Sunday',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 244,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'Robin',
    char_three: 'Sunday',
    char_four: 'Lingsha',
    app_rate: 0.03,
    rank: 244,
    avg_round: 3042,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Herta',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.03,
    rank: 244,
    avg_round: 2977,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Sparkle',
    char_four: 'Gepard',
    app_rate: 0.03,
    rank: 244,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Acheron',
    char_three: 'Jiaoqiu',
    char_four: 'Aventurine',
    app_rate: 0.03,
    rank: 244,
    avg_round: 3357,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'Bronya',
    char_three: 'Sunday',
    char_four: 'Huohuo',
    app_rate: 0.03,
    rank: 244,
    avg_round: 3394,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Sparkle',
    char_three: 'Sunday',
    char_four: 'Huohuo',
    app_rate: 0.03,
    rank: 244,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Himeko',
    char_three: 'Jiaoqiu',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 244,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Rappa',
    char_two: 'Jade',
    char_three: 'trailblazer-harmony',
    char_four: 'Gallagher',
    app_rate: 0.03,
    rank: 244,
    avg_round: 3510,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'Jiaoqiu',
    char_four: 'Huohuo',
    app_rate: 0.03,
    rank: 244,
    avg_round: 3125,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'topaz',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.03,
    rank: 244,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'march-7th-swordmaster',
    char_three: 'trailblazer-harmony',
    char_four: 'Lingsha',
    app_rate: 0.03,
    rank: 244,
    avg_round: 3209,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Robin',
    char_four: 'Gepard',
    app_rate: 0.03,
    rank: 244,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Argenti',
    char_three: 'Sunday',
    char_four: 'Aventurine',
    app_rate: 0.03,
    rank: 244,
    avg_round: 3165,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 244,
    avg_round: 3369,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Guinaifen',
    char_three: 'Pela',
    char_four: 'Gepard',
    app_rate: 0.03,
    rank: 244,
    avg_round: 3304,
    star_num: '4'
  },
  {
    char_one: 'march-7th-swordmaster',
    char_two: 'Robin',
    char_three: 'Sunday',
    char_four: 'Lingsha',
    app_rate: 0.03,
    rank: 244,
    avg_round: 3430,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'black-swan',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 244,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Rappa',
    char_two: 'trailblazer-harmony',
    char_three: 'Sunday',
    char_four: 'Aventurine',
    app_rate: 0.03,
    rank: 244,
    avg_round: 3388,
    star_num: '4'
  },
  {
    char_one: 'Rappa',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 244,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'ruan-mei',
    char_three: 'Sparkle',
    char_four: 'Gallagher',
    app_rate: 0.03,
    rank: 244,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'silver-wolf',
    char_four: 'Huohuo',
    app_rate: 0.03,
    rank: 244,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Welt',
    char_three: 'Sunday',
    char_four: 'Aventurine',
    app_rate: 0.03,
    rank: 244,
    avg_round: 3183,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Himeko',
    char_three: 'Robin',
    char_four: 'Huohuo',
    app_rate: 0.03,
    rank: 244,
    avg_round: 3042,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'march-7th-swordmaster',
    char_three: 'Robin',
    char_four: 'Huohuo',
    app_rate: 0.03,
    rank: 244,
    avg_round: 2897,
    star_num: '4'
  },
  {
    char_one: 'Rappa',
    char_two: 'Himeko',
    char_three: 'trailblazer-harmony',
    char_four: 'Lingsha',
    app_rate: 0.03,
    rank: 244,
    avg_round: 3280,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Robin',
    char_three: 'Sparkle',
    char_four: 'Luocha',
    app_rate: 0.03,
    rank: 244,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Rappa',
    char_two: 'ruan-mei',
    char_three: 'Sunday',
    char_four: 'Gallagher',
    app_rate: 0.03,
    rank: 244,
    avg_round: 3195,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Himeko',
    char_three: 'Robin',
    char_four: 'Lingsha',
    app_rate: 0.03,
    rank: 244,
    avg_round: 3322,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'black-swan',
    char_three: 'Pela',
    char_four: 'Lingsha',
    app_rate: 0.03,
    rank: 244,
    avg_round: 3316,
    star_num: '4'
  },
  {
    char_one: 'Rappa',
    char_two: 'trailblazer-harmony',
    char_three: 'Jiaoqiu',
    char_four: 'Gallagher',
    app_rate: 0.03,
    rank: 244,
    avg_round: 3432,
    star_num: '4'
  },
  {
    char_one: 'Rappa',
    char_two: 'trailblazer-harmony',
    char_three: 'Pela',
    char_four: 'Luocha',
    app_rate: 0.03,
    rank: 244,
    avg_round: 3371,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Welt',
    char_three: 'Jiaoqiu',
    char_four: 'Gallagher',
    app_rate: 0.03,
    rank: 244,
    avg_round: 3259,
    star_num: '4'
  },
  {
    char_one: 'Rappa',
    char_two: 'march-7th-swordmaster',
    char_three: 'trailblazer-harmony',
    char_four: 'Lingsha',
    app_rate: 0.03,
    rank: 244,
    avg_round: 3484,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'ruan-mei',
    char_four: 'Lingsha',
    app_rate: 0.03,
    rank: 244,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'Guinaifen',
    char_four: 'Gallagher',
    app_rate: 0.03,
    rank: 244,
    avg_round: 3381,
    star_num: '4'
  },
  {
    char_one: 'Rappa',
    char_two: 'Robin',
    char_three: 'Sunday',
    char_four: 'Aventurine',
    app_rate: 0.03,
    rank: 244,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 244,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'Robin',
    char_four: 'Huohuo',
    app_rate: 0.03,
    rank: 244,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Herta',
    char_two: 'Robin',
    char_three: 'Sunday',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 244,
    avg_round: 3018,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'Guinaifen',
    char_four: 'Aventurine',
    app_rate: 0.03,
    rank: 244,
    avg_round: 3255,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'Sunday',
    char_three: 'Tingyun',
    char_four: 'Bailu',
    app_rate: 0.03,
    rank: 244,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Herta',
    char_three: 'Pela',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 244,
    avg_round: 3052,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'Jiaoqiu',
    char_three: 'Sunday',
    char_four: 'Huohuo',
    app_rate: 0.03,
    rank: 244,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Robin',
    char_three: 'Sparkle',
    char_four: 'Lingsha',
    app_rate: 0.03,
    rank: 244,
    avg_round: 3510,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Welt',
    char_three: 'Pela',
    char_four: 'Lingsha',
    app_rate: 0.03,
    rank: 244,
    avg_round: 3390,
    star_num: '4'
  },
  {
    char_one: 'Jingliu',
    char_two: 'Bronya',
    char_three: 'ruan-mei',
    char_four: 'Luocha',
    app_rate: 0.03,
    rank: 244,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'Sparkle',
    char_three: 'Sunday',
    char_four: 'Lingsha',
    app_rate: 0.03,
    rank: 244,
    avg_round: 3338,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.03,
    rank: 244,
    avg_round: 3266,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Blade',
    char_three: 'Bronya',
    char_four: 'Luocha',
    app_rate: 0.03,
    rank: 244,
    avg_round: 3311,
    star_num: '4'
  },
  {
    char_one: 'Rappa',
    char_two: 'trailblazer-harmony',
    char_three: 'Sunday',
    char_four: 'Gallagher',
    app_rate: 0.03,
    rank: 244,
    avg_round: 3392,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'Pela',
    char_three: 'Sparkle',
    char_four: 'Aventurine',
    app_rate: 0.03,
    rank: 244,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'march-7th-swordmaster',
    char_three: 'Sunday',
    char_four: 'Aventurine',
    app_rate: 0.03,
    rank: 244,
    avg_round: 3299,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Pela',
    char_four: 'Sparkle',
    app_rate: 0.02,
    rank: 308,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'march-7th-swordmaster',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 308,
    avg_round: 3077,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Bronya',
    char_three: 'Robin',
    char_four: 'Lingsha',
    app_rate: 0.02,
    rank: 308,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Herta',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 308,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'Herta',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 308,
    avg_round: 3097,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'Moze',
    char_three: 'Sunday',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 308,
    avg_round: 3410,
    star_num: '4'
  },
  {
    char_one: 'Herta',
    char_two: 'Robin',
    char_three: 'Sparkle',
    char_four: 'Lingsha',
    app_rate: 0.02,
    rank: 308,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Guinaifen',
    char_three: 'Bronya',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 308,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Himeko',
    char_three: 'Pela',
    char_four: 'Lingsha',
    app_rate: 0.02,
    rank: 308,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Robin',
    char_three: 'Sparkle',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 308,
    avg_round: 3036,
    star_num: '4'
  },
  {
    char_one: 'Rappa',
    char_two: 'Robin',
    char_three: 'Sunday',
    char_four: 'Lingsha',
    app_rate: 0.02,
    rank: 308,
    avg_round: 3234,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'Moze',
    char_three: 'Jiaoqiu',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 308,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'trailblazer-harmony',
    char_three: 'Pela',
    char_four: 'Lingsha',
    app_rate: 0.02,
    rank: 308,
    avg_round: 3210,
    star_num: '4'
  },
  {
    char_one: 'Herta',
    char_two: 'Pela',
    char_three: 'Robin',
    char_four: 'Lingsha',
    app_rate: 0.02,
    rank: 308,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'Pela',
    char_three: 'Sunday',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 308,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'ruan-mei',
    char_four: 'Sparkle',
    app_rate: 0.02,
    rank: 308,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Rappa',
    char_two: 'Firefly',
    char_three: 'ruan-mei',
    char_four: 'Lingsha',
    app_rate: 0.02,
    rank: 308,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'Robin',
    char_four: 'Lingsha',
    app_rate: 0.02,
    rank: 308,
    avg_round: 3383,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'Robin',
    char_three: 'Sunday',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 308,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Rappa',
    char_two: 'Sparkle',
    char_three: 'Sunday',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 308,
    avg_round: 3087,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'Sparkle',
    char_three: 'Tingyun',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 308,
    avg_round: 3208,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Blade',
    char_three: 'Robin',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 308,
    avg_round: 3335,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'black-swan',
    char_three: 'Bronya',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 308,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Himeko',
    char_three: 'Pela',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 308,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'Robin',
    char_three: 'Sunday',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 308,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Yunli',
    char_three: 'Pela',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 308,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Rappa',
    char_two: 'Himeko',
    char_three: 'Sunday',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 308,
    avg_round: 3117,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'ruan-mei',
    char_three: 'Sunday',
    char_four: 'Lingsha',
    app_rate: 0.02,
    rank: 308,
    avg_round: 3426,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'Sparkle',
    char_three: 'Tingyun',
    char_four: 'Lingsha',
    app_rate: 0.02,
    rank: 308,
    avg_round: 3273,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'trailblazer-harmony',
    char_three: 'Sunday',
    char_four: 'Lingsha',
    app_rate: 0.02,
    rank: 308,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'black-swan',
    char_three: 'Pela',
    char_four: 'Bailu',
    app_rate: 0.02,
    rank: 308,
    avg_round: 3348,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'silver-wolf',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 308,
    avg_round: 3085,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'Sparkle',
    char_three: 'Sunday',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 308,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Jingliu',
    char_two: 'ruan-mei',
    char_three: 'Sunday',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 308,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Pela',
    char_three: 'Robin',
    char_four: 'Lingsha',
    app_rate: 0.02,
    rank: 308,
    avg_round: 3484,
    star_num: '4'
  },
  {
    char_one: 'march-7th-swordmaster',
    char_two: 'trailblazer-harmony',
    char_three: 'Sunday',
    char_four: 'Lingsha',
    app_rate: 0.02,
    rank: 308,
    avg_round: 3155,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'Sunday',
    char_three: 'Tingyun',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 308,
    avg_round: 3175,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'Jiaoqiu',
    char_three: 'Sparkle',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 308,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Robin',
    char_three: 'Sunday',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 308,
    avg_round: 3011,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'Bronya',
    char_three: 'trailblazer-harmony',
    char_four: 'Lingsha',
    app_rate: 0.02,
    rank: 308,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Bronya',
    char_three: 'Jiaoqiu',
    char_four: 'Gepard',
    app_rate: 0.02,
    rank: 308,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Serval',
    char_two: 'Bronya',
    char_three: 'Sparkle',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 308,
    avg_round: 3215,
    star_num: '4'
  },
  {
    char_one: 'Rappa',
    char_two: 'Jade',
    char_three: 'trailblazer-harmony',
    char_four: 'ruan-mei',
    app_rate: 0.02,
    rank: 308,
    avg_round: 3533,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'Robin',
    char_three: 'Sparkle',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 308,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Clara',
    char_three: 'Pela',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 308,
    avg_round: 2999,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Blade',
    char_three: 'Sunday',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 308,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Jingliu',
    char_two: 'Bronya',
    char_three: 'Pela',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 308,
    avg_round: 3210,
    star_num: '4'
  },
  {
    char_one: 'Jingliu',
    char_two: 'Pela',
    char_three: 'Sunday',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 308,
    avg_round: 3347,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Yunli',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 308,
    avg_round: 3197,
    star_num: '4'
  },
  {
    char_one: 'Jingliu',
    char_two: 'ruan-mei',
    char_three: 'Sunday',
    char_four: 'Lingsha',
    app_rate: 0.02,
    rank: 308,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Blade',
    char_three: 'Sunday',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 308,
    avg_round: 3192,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'ruan-mei',
    char_three: 'Sunday',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 308,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Rappa',
    char_two: 'Jade',
    char_three: 'Robin',
    char_four: 'Lingsha',
    app_rate: 0.02,
    rank: 308,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'Luocha',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 308,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Welt',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 308,
    avg_round: 3261,
    star_num: '4'
  },
  {
    char_one: 'Jingliu',
    char_two: 'Robin',
    char_three: 'Sparkle',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 308,
    avg_round: 2912,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'silver-wolf',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 308,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Yunli',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 308,
    avg_round: 3086,
    star_num: '4'
  },
  {
    char_one: 'Rappa',
    char_two: 'trailblazer-harmony',
    char_three: 'Sparkle',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 308,
    avg_round: 3286,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Bronya',
    char_three: 'Jiaoqiu',
    char_four: 'Lingsha',
    app_rate: 0.02,
    rank: 308,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'Sparkle',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 308,
    avg_round: 3304,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'Robin',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 308,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Bronya',
    char_three: 'Pela',
    char_four: 'Gepard',
    app_rate: 0.02,
    rank: 308,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'trailblazer-harmony',
    char_three: 'Sunday',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 308,
    avg_round: 3072,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'Jade',
    char_three: 'Sunday',
    char_four: 'Lingsha',
    app_rate: 0.02,
    rank: 308,
    avg_round: 3573,
    star_num: '4'
  },
  {
    char_one: 'topaz',
    char_two: 'Robin',
    char_three: 'Sunday',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 308,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'Sparkle',
    char_three: 'Tingyun',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 308,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Rappa',
    char_two: 'trailblazer-harmony',
    char_three: 'Sunday',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 308,
    avg_round: 3475,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'Herta',
    char_three: 'Sunday',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 308,
    avg_round: 3039,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'Robin',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 308,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'Sparkle',
    char_three: 'Tingyun',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 308,
    avg_round: 3286,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'Sunday',
    char_four: 'Bailu',
    app_rate: 0.02,
    rank: 308,
    avg_round: 3135,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'ruan-mei',
    char_three: 'Sparkle',
    char_four: 'Lingsha',
    app_rate: 0.02,
    rank: 308,
    avg_round: 3622,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'silver-wolf',
    char_four: 'Lingsha',
    app_rate: 0.02,
    rank: 308,
    avg_round: 3460,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'trailblazer-harmony',
    char_three: 'Robin',
    char_four: 'Lingsha',
    app_rate: 0.02,
    rank: 308,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'Guinaifen',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 308,
    avg_round: 3238,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'Welt',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 308,
    avg_round: 3206,
    star_num: '4'
  },
  {
    char_one: 'Rappa',
    char_two: 'Jade',
    char_three: 'Tingyun',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 308,
    avg_round: 3110,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'Robin',
    char_three: 'Sunday',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 308,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'Yunli',
    char_three: 'Robin',
    char_four: 'Lingsha',
    app_rate: 0.02,
    rank: 308,
    avg_round: 3345,
    star_num: '4'
  },
  {
    char_one: 'Rappa',
    char_two: 'trailblazer-harmony',
    char_three: 'Tingyun',
    char_four: 'Lingsha',
    app_rate: 0.02,
    rank: 308,
    avg_round: 3429,
    star_num: '4'
  },
  {
    char_one: 'Welt',
    char_two: 'Robin',
    char_three: 'Sunday',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 308,
    avg_round: 3041,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'Robin',
    char_three: 'Sparkle',
    char_four: 'Lingsha',
    app_rate: 0.02,
    rank: 308,
    avg_round: 3089,
    star_num: '4'
  },
  {
    char_one: 'Rappa',
    char_two: 'Firefly',
    char_three: 'trailblazer-harmony',
    char_four: 'ruan-mei',
    app_rate: 0.02,
    rank: 308,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'Sunday',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 308,
    avg_round: 3393,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'black-swan',
    char_three: 'Welt',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 308,
    avg_round: 3239,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'Robin',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 308,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Yunli',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 308,
    avg_round: 3148,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Bronya',
    char_three: 'Pela',
    char_four: 'Lingsha',
    app_rate: 0.02,
    rank: 308,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Pela',
    char_three: 'Tingyun',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 308,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Sunday',
    char_four: 'Gepard',
    app_rate: 0.02,
    rank: 308,
    avg_round: 3474,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Feixiao',
    char_three: 'Robin',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 308,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Rappa',
    char_two: 'trailblazer-harmony',
    char_three: 'Robin',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 308,
    avg_round: 3219,
    star_num: '4'
  },
  {
    char_one: 'Robin',
    char_two: 'Sparkle',
    char_three: 'Tingyun',
    char_four: 'Lingsha',
    app_rate: 0.02,
    rank: 308,
    avg_round: 2974,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'Jiaoqiu',
    char_three: 'Sunday',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 308,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Robin',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 308,
    avg_round: 3142,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'Pela',
    char_three: 'Sparkle',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 308,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Serval',
    char_two: 'Sparkle',
    char_three: 'Sunday',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 308,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'black-swan',
    char_three: 'Bronya',
    char_four: 'Lingsha',
    app_rate: 0.02,
    rank: 308,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Acheron',
    char_three: 'Pela',
    char_four: 'Lingsha',
    app_rate: 0.02,
    rank: 308,
    avg_round: 3456,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Serval',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 308,
    avg_round: 3300,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'Sparkle',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 308,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'imbibitor-lunae',
    char_three: 'Sparkle',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 308,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Welt',
    char_three: 'silver-wolf',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 308,
    avg_round: 3179,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'Pela',
    char_three: 'Sparkle',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 308,
    avg_round: 3145,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'black-swan',
    char_three: 'Pela',
    char_four: 'Gepard',
    app_rate: 0.02,
    rank: 308,
    avg_round: 3270,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Blade',
    char_three: 'Robin',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 308,
    avg_round: 3236,
    star_num: '4'
  },
  {
    char_one: 'Rappa',
    char_two: 'Himeko',
    char_three: 'Robin',
    char_four: 'Lingsha',
    app_rate: 0.02,
    rank: 308,
    avg_round: 3274,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Himeko',
    char_three: 'Robin',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 308,
    avg_round: 3164,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'Sparkle',
    char_four: 'Bailu',
    app_rate: 0.02,
    rank: 308,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'march-7th-swordmaster',
    char_three: 'Sparkle',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 308,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Rappa',
    char_two: 'trailblazer-harmony',
    char_three: 'Sunday',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 308,
    avg_round: 3345,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'Jiaoqiu',
    char_three: 'Sunday',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 308,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Argenti',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 308,
    avg_round: 3299,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'topaz',
    char_three: 'Jiaoqiu',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 308,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Rappa',
    char_two: 'Acheron',
    char_three: 'Pela',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 308,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'Sunday',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 308,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'Guinaifen',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 308,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'Sparkle',
    char_three: 'Tingyun',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 308,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Rappa',
    char_two: 'trailblazer-harmony',
    char_three: 'Tingyun',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 308,
    avg_round: 3374,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'Robin',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 308,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Herta',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 308,
    avg_round: 3067,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Sparkle',
    char_four: 'Sunday',
    app_rate: 0.02,
    rank: 308,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Jingliu',
    char_two: 'Bronya',
    char_three: 'Robin',
    char_four: 'Lingsha',
    app_rate: 0.02,
    rank: 308,
    avg_round: 3109,
    star_num: '4'
  },
  {
    char_one: 'Jingliu',
    char_two: 'Pela',
    char_three: 'Sunday',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 308,
    avg_round: 2928,
    star_num: '4'
  },
  {
    char_one: 'Rappa',
    char_two: 'march-7th-swordmaster',
    char_three: 'ruan-mei',
    char_four: 'Lingsha',
    app_rate: 0.02,
    rank: 308,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Himeko',
    char_three: 'Robin',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 308,
    avg_round: 3418,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'Robin',
    char_four: 'Lingsha',
    app_rate: 0.02,
    rank: 308,
    avg_round: 3186,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Acheron',
    char_three: 'Jiaoqiu',
    char_four: 'Lingsha',
    app_rate: 0.02,
    rank: 308,
    avg_round: 3313,
    star_num: '4'
  },
  {
    char_one: 'Rappa',
    char_two: 'ruan-mei',
    char_three: 'Sunday',
    char_four: 'Lingsha',
    app_rate: 0.02,
    rank: 308,
    avg_round: 3493,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Feixiao',
    char_three: 'Robin',
    char_four: 'Lingsha',
    app_rate: 0.02,
    rank: 308,
    avg_round: 3183,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'Sparkle',
    char_four: 'Lingsha',
    app_rate: 0.02,
    rank: 308,
    avg_round: 3398,
    star_num: '4'
  },
  {
    char_one: 'Rappa',
    char_two: 'Acheron',
    char_three: 'Kafka',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 308,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'march-7th-swordmaster',
    char_three: 'Pela',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 308,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Robin',
    char_three: 'silver-wolf',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 308,
    avg_round: 2967,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'Sparkle',
    char_three: 'Tingyun',
    char_four: 'Lingsha',
    app_rate: 0.02,
    rank: 308,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Sampo',
    char_three: 'Pela',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 308,
    avg_round: 3275,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Clara',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 308,
    avg_round: 3119,
    star_num: '4'
  },
  {
    char_one: 'Jingliu',
    char_two: 'Bronya',
    char_three: 'Robin',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 308,
    avg_round: 0,
    star_num: '4'
  }
];
